const initialState = {
  mobileNavOpen: false
}

const MobileNavReducer = (state = initialState, action) => {
  switch(action.type) {
    case "MOBILE_NAV_TOGGLE":
      return {
        ...state,
        mobileNavOpen: action.payload.isOpen
      }

    default:
      return state
  }
}

export default MobileNavReducer
