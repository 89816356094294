const initialState = {
  headerTransition: false,
}

const HeaderReducer = (state = initialState, action) => {
  switch(action.type) {
    case "HEADER_TRANSITION":
      return {
        ...state,
        headerTransition: action.payload
      }

    default:
      return state
  }
}

export default HeaderReducer
