const initialState = {
  showModal: false,
  modalType: null,
  url: null,
}

const modalReducer = (state = initialState, action) => {
  switch(action.type) {
    case "SHOW_VIDEO_MODAL":
      return {
        ...state,
        showModal: action.payload.isOpen,
        modalType: "video",
        url: action.payload.url
      }

    case "CLOSE_MODAL":
      return {
        ...state,
        showModal: false,
        modalType: null,
        url: null
      }

    default:
      return state
  }
}

export default modalReducer
