import { combineReducers } from "redux"
import header from "./headerReducer"
import modal from "./modalReducer"
import mobileNav from "./mobileNavReducer"
import cookies from "./cookieReducer"

const Reducers = combineReducers({
  header,
  modal,
  mobileNav,
  cookies
})

export default Reducers
