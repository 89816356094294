/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import scrollToElement from "scroll-to-element"
export { default as wrapRootElement } from "./src/store/reduxWrapper"

let query

const checkHash = location => {
  let { hash } = location
  if (hash) {
    scrollToElement(hash, {
      offset: -100,
      duration: 0,
    })
  }
}

const iframeSizer = () => {
  const script = () => {
    const iframe = document.getElementById("pardot-form")
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent"
    const eventer = window[eventMethod]
    const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message"
    if (iframe) {
      eventer(
        messageEvent,
        e => {
          if (isNaN(e.data)) return
          iframe.style.height = e.data + "px"
        },
        false
      )
    }
  }
  return script()
}

const gtmTracker = () => {
  const s = document.createElement(`script`)
  s.type = `text/javascript`
  s.innerHTML = `piAId = '272292'; piCId = '29489'; piHostname = 'go.watershedlrs.com'; (function() {    function async_load(){        var s = document.createElement('script'); s.type = 'text/javascript';        s.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + piHostname + '/pd.js';        var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);    }    if(window.attachEvent) { window.attachEvent('onload', async_load); }    else { window.addEventListener('load', async_load, false); } })();`
  document.body.appendChild(s)

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    originalQuery:
      window.location && window.location.search !== query
        ? window.location
        : window.location.href,
  })
}

export const onClientEntry = () => {
  query = window.location ? window.location.search : undefined
}

export const onRouteUpdate = ({ location }) => {
  checkHash(location)
  iframeSizer()
  gtmTracker()
  if (typeof window.piTracker !== "undefined") window.piTracker(location)
}
